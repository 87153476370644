/* This contains utility classes in TailwindCSS flavour */

.mt-2 {
  margin-top: 0.5rem !important; /* 8px */
}

.mt-6 {
  margin-top: 1.5rem !important; /* 24px */
}

.mt-8 {
  margin-top: 2rem !important; /* 32px */
}

.mt-12 {
  margin-top: 3rem !important; /* 48px */
}

.ml-2 {
  margin-left: 0.5rem !important; /* 8px */
}

.mr-4\.5 {
  margin: 1.125rem !important; /* 18px */
}

.font-bold {
  font-weight: var(--font-weight-bold, 700);
}
